import React, {useEffect, useState} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useLocation, useNavigate} from 'react-router-dom';
import {getSafeAreaPadding} from "../utils";

const useHistoryTracker = () => {
    const [historyStack, setHistoryStack] = useState<Location[]>([]);
    const [shouldDisplay, setShouldDisplay] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const hasPastScreens = window.history.length > 1;
        const hasModalInHistory = window.history.state?.key && window.history.state.key.includes('modal');

        // Only display the button if there are past screens and none include "modal"
        setShouldDisplay(hasPastScreens && !hasModalInHistory);

        setHistoryStack((prev: any) => [...prev, location]);
    }, [location]);

    const getPreviousLocation = (steps: number = 2) => {
        if (historyStack.length < steps) return null;
        return historyStack[historyStack.length - steps];
    };

    return {getPreviousLocation, shouldDisplay};
};

const BackButton: React.FC = () => {
    const navigate = useNavigate();
    const {getPreviousLocation, shouldDisplay} = useHistoryTracker();
    const location = useLocation();
    const noButtonPages = ['/', '', '/events', '/friendships', '/activity', '/my-profile']

    function hasSignificantChange(prevLocation: Location) {
        if (window.location.pathname !== prevLocation.pathname) {
            return true
        } else if (window.location.search !== prevLocation.search) {
            return true
        }
        const prevHash = new URLSearchParams(prevLocation.hash.slice(1)); // Slice to remove the leading '#'
        const curHash = new URLSearchParams(window.location.hash.slice(1)); // Slice to remove the leading '#'
        for (const [key, value] of curHash.entries()) {
            if (!key.startsWith('refresh') && prevHash.get(key) !== value) {
                return true; // Significant change found
            }
        }
        return false;
    }

    const handleBackClick = () => {
        let steps = 1
        let prevLocation = getPreviousLocation(steps);
        console.log(`Previous location ${steps}`, prevLocation);

        while (prevLocation && !hasSignificantChange(prevLocation)) {
            steps += 1
            prevLocation = getPreviousLocation(steps);
            console.log(`Previous location ${steps}`, prevLocation);

        }
        // Only navigate if a significant change is found
        if (steps > 1) {
            navigate(-steps + 1); // Navigate back by the number of steps found
        } else {
            navigate(-1); // Default back navigation
        }
    };

    const isSkipPage = noButtonPages.indexOf(location.pathname) > -1

    if (!shouldDisplay || isSkipPage) {
        return null;
    }

    const paddingTop = getSafeAreaPadding('paddingTop') + 20;

    return (
        <div
            color="inherit"
            aria-label="go back"
            onClick={handleBackClick}
            style={{position: 'fixed', backgroundColor: "transparent", top: paddingTop, left: 15, zIndex: 99}}
        >
            <ArrowBackIcon/>
        </div>
    );
};

export default BackButton;
